const adj = [
  "Nobiles",
  "Dynamici",
  "Uniti",
  "Rugientes",
  "Ascendentes",
  "Potentes",
  "Aurei",
  "Aeterni",
  "Iuvenes",
  "Senes",
  "Fortes",
  "Stellares",
  "Victoriosi",
  "Radiantes",
  "Gloriosi",
  "Intrépidi",
  "Leggendarii",
  "Supremi",
  "Audaces",
  "Valentes",
  "Feroces",
  "Invincibiles",
  "Agiles",
  "Spiritosi",
  "Majestosi",
  "Loyales",
  "Appassionati",
  "Spiritosi",
  "Dominantes",
  "Ambitiosi",
  "Fulmina",
  "Stellae",
  "Gigantes",
];

module.exports = adj;
