const animals = [
  "Leones",
  "Tigres",
  "Osos",
  "Lobos",
  "Osos",
  "Halcones",
  "Halcones",
  "Panteras",
  "Jaguares",
  "Guepardos",
  "Leopardos",
  "Cobras",
  "Víboras",
  "Tiburones",
  "Delfines",
  "Ballenas",
  "Orcas",
  "Cocodrilos",
  "Caimanes",
  "Toros",
  "Broncos",
  "Mustangs",
  "Sementales",
  "Carneros",
  "Gatos monteses",
  "Linces",
  "Linces",
  "Pumas",
  "Cuervos",
  "Búhos",
  "Cebra",
  "Jirafas",
  "Gacelas",
  "Rinocerontes",
  "Elefantes",
  "Perros",
  "Cabras",
  "Zorros",
  "Ciervos",
  "Pandas",
  "Coyotes",
  "Búfalos",
  "Serpientes",
  "Caballos",
  "Akitas",
  "Murciélagos",
  "Camellos",
  "Corales",
  "Águilas",
  "Alces",
  "Gorilas",
  "Loros",
  "Conejos",
  "Vieiras",
  "Calamares",
  "Cisnes",
  "Buitre",
  "Yaks",
  "Escorpiones",
];

module.exports = animals;
