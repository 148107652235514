const adj = [
  "Nobili",
  "Dinamici",
  "Uniti",
  "Ruggenti",
  "In ascesa",
  "Potenti",
  "Dorati",
  "Eterni",
  "Giovani",
  "Vecchi",
  "Coraggiosi",
  "Stellari",
  "Vittoriosi",
  "Radianti",
  "Gloriosi",
  "Intrépidi",
  "Leggendari",
  "Supremi",
  "Audaci",
  "Valorosi",
  "Feroci",
  "Invincibili",
  "Agili",
  "Spiritosi",
  "Maestosi",
  "Leali",
  "Appassionati",
  "Spiritosi",
  "Dominanti",
  "Ambiziosi",
  "Fulmini",
  "Stelle",
  "Giganti",
];

module.exports = adj;
