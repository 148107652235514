const colors = [
  "Rossi",
  "Blu",
  "Verdi",
  "Gialli",
  "Viola",
  "Rosa",
  "Marroni",
  "Arancioni",
  "Grigi",
  "Neri",
  "Bianchi",
  "Oro",
  "Argento",
  "Turchesi",
  "Indaco",
  "Magenta",
  "Violetta",
  "Azzurri",
  "Marrone scuro",
  "Ambra",
  "Azzurro",
  "Aureolina",
  "Siena",
  "Carminio",
  "Celeste",
  "Ciliegia",
  "Ceruleo",
  "Fucsia",
  "Lino",
  "Fiamme",
  "Finlandesi",
  "Camoscio",
  "Smeraldi",
  "Bianco uovo",
  "Crema",
];

module.exports = colors;
