const names = [
  "Titans",
  "Storm",
  "Warriors",
  "Thunder",
  "Dragons",
  "Rockets",
  "Phoenix",
  "Waves",
  "Avalanche",
  "Hammerheads",
  "Hurricanes",
  "Mavericks",
  "Hammer",
  "Shield",
  "Arrows",
  "Sabers",
  "Trident",
  "Gauntlet",
  "Torch",
  "Armor",
  "Wings",
  "Crown",
  "Anchors",
  "Blade",
  "Scepter",
  "Helm",
  "Dagger",
  "Sphere",
  "Orb",
  "Talon",
  "Crest",
  "Raptors",
  "Blazers",
  "Firebirds",
  "Guardians",
  "Cyclones",
  "Comets",
  "Outlaws",
  "Phantoms",
  "Stormers",
  "Chargers",
  "Stormhawks",
  "Raiders",
  "Vikings",
  "Inferno",
  "Prowlers",
  "Thunderbolts",
  "Gryphons",
  "Rebels",
  "Strikers",
  "Trojans",
  "Kings",
  "Cobalts",
  "Shadows",
  "Buccaneers",
  "Jets",
  "Wizards",
  "Griffins",
  "Royals",
  "Legends",
  "Fury",
  "Archers",
  "Stingrays",
  "Galaxy",
  "Cliffs",
];

module.exports = names;
