const adj = [
  "Noble",
  "Dinámico",
  "Unido",
  "Rugiente",
  "Ascendente",
  "Poderoso",
  "Dorado",
  "Eterno",
  "Joven",
  "Viejo",
  "Valiente",
  "Estelar",
  "Victorioso",
  "Radiante",
  "Glorioso",
  "Intrépido",
  "Legendario",
  "Supremo",
  "Audaz",
  "Valiente",
  "Feroz",
  "Invencible",
  "Ágil",
  "Enérgico",
  "Majestuoso",
  "Leal",
  "Apasionado",
  "Espiritado",
  "Dominante",
  "Ambicioso",
  "Relámpagos",
  "Estrellas",
  "Gigantes",
];

module.exports = adj;
