const occupations = [
  "Bakers",
  "Tailors",
  "Farmers",
  "Painters",
  "Sailors",
  "Engineers",
  "Writers",
  "Athletes",
  "Potters",
  "Weavers",
  "Masons",
  "Carpenters",
  "Smiths",
  "Pilots",
  "Officers",
  "Gardeners",
  "Mechanics",
  "Shoemakers",
  "Hunters",
  "Plumbers",
  "Builders",
  "Coopers",
  "Millers",
  "Fullers",
  "Grooms",
  "Yeomen",
  "Glassblowers",
  "Vintners",
  "Ropemakers",
  "Plowmen",
  "Innkeepers",
  "Haberdashers",
  "Lutemakers",
  "Illuminators",
  "Chapmen",
  "Alewives",
  "Tallow Chandlers",
  "Spindle Makers",
  "Sievemakers",
  "Scribes",
  "Ploughmen",
  "Longbowmen",
  "Ironmongers",
  "Glovers",
  "Farriers",
  "Bowyers",
  "Bellmakers",
  "Wickerworkers",
  "Scabbard Makers",
  "Ostlers",
  "Harrowers",
  "Gilders",
  "Fletchers",
  "Bards",
  "Alewives",
  "Wheelwrights",
  "Pardoners",
  "Reeves",
  "Tinkers",
  "Websters",
  "Pardoners",
  "Joiners",
  "Saints",
  "Gladiators",
  "Gunners",
];

module.exports = occupations;
