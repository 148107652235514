const adj = [
  "Noble",
  "Dynamique",
  "Uni",
  "Rugissant",
  "Émergeant",
  "Puissant",
  "Doré",
  "Éternel",
  "Jeune",
  "Vieux",
  "Brave",
  "Stellaire",
  "Victorieux",
  "Rayonnant",
  "Glorieux",
  "Intrépide",
  "Légendaire",
  "Suprême",
  "Audacieux",
  "Vaillant",
  "Féroce",
  "Invincible",
  "Agile",
  "Animé",
  "Majestueux",
  "Loyal",
  "Passionné",
  "Animé",
  "Dominant",
  "Ambitieux",
  "Éclairs",
  "Étoiles",
  "Géants",
];

module.exports = adj;
