const historicReferences = [
  "Real",
  "Sporting",
  "Locomotive",
  "Dynamo",
  "Olympic",
  "Union",
  "Inter",
  "Legia",
  "Olimpique",
  "Sporting CP",
  "CSKA",
];

module.exports = historicReferences;
