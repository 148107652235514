const adj = [
  "Edle",
  "Dynamische",
  "Vereinte",
  "Brüllende",
  "Aufstrebende",
  "Mächtige",
  "Goldene",
  "Ewige",
  "Junge",
  "Alte",
  "Tapfere",
  "Stellare",
  "Siegreiche",
  "Strahlende",
  "Glorreiche",
  "Furchtlose",
  "Legendäre",
  "Höchste",
  "Mutige",
  "Tapfere",
  "Wilde",
  "Unbesiegbare",
  "Agile",
  "Lebhafte",
  "Majestätische",
  "Treue",
  "Leidenschaftliche",
  "Lebhafte",
  "Dominante",
  "Ambitionierte",
  "Blitze",
  "Sterne",
  "Riesen",
];

module.exports = adj;
