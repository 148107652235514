const legends = [
  "Achilles",
  "Zeus",
  "Hercules",
  "Beowulf",
  "Robin Hood",
  "Cleopatra",
  "Odin",
  "Thor",
  "Athena",
  "Merlin",
  "Spartacus",
  "da Vinci",
  "Napoleon",
  "Shakespeare",
  "Michelangelo",
  "Saladin",
  "Confucius",
  "Archimedes",
  "Newton",
  "Galileo Galilei",
  "Einstein",
  "Gandhi",
  "Luther King",
  "Earhart",
  "Curie",
  "Kahlo",
  "van Gogh",
  "Mozart",
  "Beethoven",
  "Picasso",
  "Dali",
  "Hemingway",
  "Churchill",
  "Lincoln",
  "Pelé",
  "Maradona",
  "Messi",
  "Bolt",
  "Poseidon",
  "Apollo",
  "Aphrodite",
  "Hera",
  "Hermes",
  "Dionsys",
  "Artemis",
  "Demeter",
  "Hephaestus",
  "Freya",
  "Freyr",
  "Tyr",
  "Frigg",
  "Balder",
  "Ra",
  "Horus",
  "Anubis",
  "Amun",
  "Thoth",
  "Bastet",
  "Oya",
  "Kali",
  "Shiva",
  "Legba",
  "Yemoja",
  "Ogun",
  "Shango",
  "Rama",
];

module.exports = legends;
