const occupations = [
  "Panaderos",
  "Sastres",
  "Agricultores",
  "Pintores",
  "Marineros",
  "Ingenieros",
  "Escritores",
  "Deportistas",
  "Alfareros",
  "Tejedores",
  "Albañiles",
  "Carpinteros",
  "Herreros",
  "Pilotos",
  "Oficiales",
  "Jardineros",
  "Mecánicos",
  "Zapateros",
  "Cazadores",
  "Fontaneros",
  "Constructores",
  "Toneleros",
  "Molineros",
  "Batanes",
  "Estabuladores",
  "Labradores",
  "Vasijeros",
  "Vinateros",
  "Cordeleros",
  "Aradores",
  "Mesoneros",
  "Merceristas",
  "Lutieres",
  "Iluminadores",
  "Comerciantes",
  "Taberneras",
  "Candeleros",
  "Hacedores de husos",
  "Hacedores de tamices",
  "Escribanos",
  "Aradores",
  "Arqueros",
  "Ferreteros",
  "Guanteros",
  "Herreros de caballos",
  "Fabricantes de arcos",
  "Fabricantes de campanas",
  "Cesteros",
  "Fabricantes de vainas",
  "Mostradores",
  "Niveladores",
  "Doradores",
  "Flecheros",
  "Bardos",
  "Taberneras",
  "Carreteros",
  "Vendedores de indulgencias",
  "Administradores",
  "Carruajeros",
  "Tejedores",
  "Vendedores de indulgencias",
  "Carpinteros",
  "Santos",
  "Gladiadores",
  "Artilleros",
];

module.exports = occupations;
