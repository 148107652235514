const adj = [
  "Noble",
  "Dynamic",
  "United",
  "Roaring",
  "Rising",
  "Mighty",
  "Golden",
  "Eternal",
  "Young",
  "Old",
  "Brave",
  "Stellar",
  "Victorious",
  "Radiant",
  "Glorious",
  "Fearless",
  "Legendary",
  "Supreme",
  "Daring",
  "Valiant",
  "Fierce",
  "Invincible",
  "Agile",
  "Spirited",
  "Majestic",
  "Loyal",
  "Passionate",
  "Spirited",
  "Dominant",
  "Ambitious",
  "Lightnings",
  "Stars",
  "Giants",
];

module.exports = adj;
