const organizationStructure = [
  "FC",
  "FC",
  "FC",
  "FC",
  "FC",
  "FC",
  "FC",
  "AFC",
  "SC",
  "AC",
  "GC",
  "SFC",
  "SC",
  "AFC",
  "SFC",
  "AS",
  "VfB",
  "CA",
  "BK",
  "BK",
  "KF",
  "FK",
  "SV",
  "CF",
  "FK",
  "KAA",
  "FF",
  "RSC",
];

module.exports = organizationStructure;
