const animals = [
  "Leones",
  "Tigres",
  "Ursi",
  "Lupi",
  "Ursi",
  "Falco",
  "Accipitres",
  "Pantherae",
  "Iaguaros",
  "Guepardos",
  "Leopardi",
  "Cobras",
  "Viperes",
  "Squali",
  "Delphini",
  "Cetacei",
  "Orcas",
  "Crocodyli",
  "Alligatoris",
  "Tauros",
  "Broncos",
  "Mustangi",
  "Equi",
  "Arietes",
  "Felis silvestres",
  "Lynxes",
  "Lynxes",
  "Pumae",
  "Corvi",
  "Bubones",
  "Zebrae",
  "Giraffae",
  "Dorcas",
  "Rhinocerotis",
  "Elephantes",
  "Canes",
  "Hirci",
  "Vulpes",
  "Cervi",
  "Pandae",
  "Coyotes",
  "Bubalos",
  "Serpentes",
  "Equi",
  "Akita",
  "Vespertilionis",
  "Cameli",
  "Corallia",
  "Aquilae",
  "Cervi",
  "Gorillae",
  "Psittaci",
  "Cuniculi",
  "Pectines",
  "Teuthides",
  "Cygnus",
  "Vultur",
  "Bos grunniens",
  "Scorpiones",
];

module.exports = animals;
