const names = [
  "Titanes",
  "Tempestas",
  "Guerrieri",
  "Tonitrus",
  "Dracones",
  "Missiles",
  "Phoenix",
  "Fluctus",
  "Avalanchae",
  "Malleolati",
  "Furacanes",
  "Mavericks",
  "Malleus",
  "Clypeus",
  "Sagittae",
  "Gladii",
  "Tridentes",
  "Manicis",
  "Fulmen",
  "Armatura",
  "Alae",
  "Corona",
  "Ancorae",
  "Gladius",
  "Sceptrum",
  "Galea",
  "Pugio",
  "Sphaera",
  "Orbis",
  "Talones",
  "Crista",
  "Raptores",
  "Fuligines",
  "Firebirds",
  "Custodes",
  "Cyclones",
  "Cometes",
  "Scelerati",
  "Phantasmata",
  "Stormers",
  "Equites",
  "Tempestatis",
  "Praedones",
  "Vichinghi",
  "Inferno",
  "Prowlers",
  "Fulmina",
  "Gryphi",
  "Rebels",
  "Ferientes",
  "Troiani",
  "Reges",
  "Cobalti",
  "Umbrae",
  "Buccanarii",
  "Ietae",
  "Magi",
  "Griffones",
  "Regii",
  "Leges",
  "Furores",
  "Sagittarii",
  "Raii",
  "Galaxiae",
  "Rupes",
];

module.exports = names;
