const occupations = [
  "Bäcker",
  "Schneider",
  "Bauern",
  "Maler",
  "Seeleute",
  "Ingenieure",
  "Schriftsteller",
  "Athleten",
  "Töpfer",
  "Weber",
  "Maurer",
  "Tischler",
  "Schmiede",
  "Piloten",
  "Offiziere",
  "Gärtner",
  "Mechaniker",
  "Schuhmacher",
  "Jäger",
  "Installateure",
  "Bauer",
  "Fassbinder",
  "Müller",
  "Färber",
  "Pferdepfleger",
  "Landwirt",
  "Glasbläser",
  "Winzer",
  "Seilmacher",
  "Pflüger",
  "Gastwirte",
  "Kaufleute",
  "Lautenmacher",
  "Illuminatoren",
  "Händler",
  "Brauerinnen",
  "Wachskerzenmacher",
  "Spindelmacher",
  "Siebfabrikanten",
  "Schreiber",
  "Pflüger",
  "Bogenschützen",
  "Eisenhändler",
  "Handschuhmacher",
  "Hufschmiede",
  "Bogenmacher",
  "Glockenmacher",
  "Korbweber",
  "Scheidenmacher",
  "Pferdeknechte",
  "Eggenmacher",
  "Vergolder",
  "Pfeilmacher",
  "Barden",
  "Brauerinnen",
  "Wagenbauer",
  "Lossprecher",
  "Vorsteher",
  "Kesselflicker",
  "Weberinnen",
  "Lossprecher",
  "Schreiner",
  "Heilige",
  "Gladiatoren",
  "Kanoniere",
];

module.exports = occupations;
