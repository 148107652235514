const animals = [
  "Löwen",
  "Tiger",
  "Bären",
  "Wölfe",
  "Bären",
  "Falken",
  "Habichte",
  "Panther",
  "Jaguare",
  "Geparden",
  "Leoparden",
  "Kobras",
  "Vipern",
  "Haie",
  "Delfine",
  "Wale",
  "Orcas",
  "Krokodile",
  "Alligatoren",
  "Bullen",
  "Broncos",
  "Mustangs",
  "Hengste",
  "Widder",
  "Wildkatzen",
  "Luchse",
  "Luchse",
  "Pumas",
  "Raben",
  "Eulen",
  "Zebras",
  "Giraffen",
  "Gazellen",
  "Nashörner",
  "Elefanten",
  "Hunde",
  "Ziegen",
  "Füchse",
  "Hirsche",
  "Pandas",
  "Kojoten",
  "Büffel",
  "Schlangen",
  "Pferde",
  "Akitas",
  "Fledermäuse",
  "Kamele",
  "Korallen",
  "Adler",
  "Elche",
  "Gorillas",
  "Papageien",
  "Hasen",
  "Jakobsmuscheln",
  "Tintenfische",
  "Schwäne",
  "Geier",
  "Yaks",
  "Skorpione",
];

module.exports = animals;
