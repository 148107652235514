const occupations = [
  "Panettieri",
  "Sarti",
  "Contadini",
  "Pittori",
  "Marinai",
  "Ingegneri",
  "Scrittori",
  "Atleti",
  "Vasai",
  "Tessitori",
  "Muratori",
  "Falegnami",
  "Fabbri",
  "Piloti",
  "Ufficiali",
  "Giardinieri",
  "Meccanici",
  "Calzolai",
  "Cacciatori",
  "Idraulici",
  "Costruttori",
  "Bottegai",
  "Molini",
  "Fulloni",
  "Palafrenieri",
  "Contadini",
  "Vetrinisti",
  "Vignaioli",
  "Cordai",
  "Aratri",
  "Ostieri",
  "Cappellai",
  "Liutai",
  "Illuminatori",
  "Mercanti",
  "Cerveciai",
  "Saponieri",
  "Fusai",
  "Setacciatori",
  "Scrittori",
  "Araldai",
  "Fabbri",
  "Glovers",
  "Ferrai",
  "Arcieri",
  "Campioni",
  "Cestai",
  "Panettieri",
  "Carrettieri",
  "Perdonatori",
  "Provveditori",
  "Lattaieri",
  "Legai",
  "Santi",
  "Gladiatori",
  "Artiglieri",
];

module.exports = occupations;
