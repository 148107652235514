const colors = [
  "Rot",
  "Blau",
  "Grün",
  "Gelb",
  "Lila",
  "Rosa",
  "Braun",
  "Orange",
  "Grau",
  "Schwarz",
  "Weiß",
  "Gold",
  "Silber",
  "Blaugrün",
  "Indigo",
  "Magenta",
  "Violett",
  "Aeros",
  "Maroon",
  "Bernstein",
  "Azur",
  "Aureolin",
  "Sienna",
  "Karminrot",
  "Himmelblau",
  "Kirsche",
  "Himmelblau",
  "Fuchsia",
  "Lein",
  "Flammen",
  "Finns",
  "Rehbraun",
  "Erins",
  "Smaragd",
  "Eierschalen",
  "Cremefarben",
];

module.exports = colors;
