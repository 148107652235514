const names = [
  "Titanes",
  "Tormenta",
  "Guerreros",
  "Trueno",
  "Dragones",
  "Cohetes",
  "Fénix",
  "Olas",
  "Avalancha",
  "Cabezas de martillo",
  "Huracanes",
  "Mavericks",
  "Martillo",
  "Escudo",
  "Flechas",
  "Sables",
  "Tridente",
  "Guantelete",
  "Antorcha",
  "Armadura",
  "Alas",
  "Corona",
  "Anclas",
  "Espada",
  "Cetro",
  "Yelmo",
  "Daga",
  "Esfera",
  "Orbe",
  "Garra",
  "Escudo",
  "Raptores",
  "Blazers",
  "Pájaros de fuego",
  "Guardianes",
  "Ciclones",
  "Cometas",
  "Forajidos",
  "Fantasmas",
  "Tempestuosos",
  "Cargadores",
  "Stormhawks",
  "Raiders",
  "Vikingos",
  "Inferno",
  "Acechadores",
  "Rayos",
  "Grifos",
  "Rebeldes",
  "Golpeadores",
  "Troyanos",
  "Reyes",
  "Cobaltos",
  "Sombras",
  "Piratas",
  "Jets",
  "Magos",
  "Grifos",
  "Reales",
  "Leyendas",
  "Furia",
  "Arqueros",
  "Rayas venenosas",
  "Galaxia",
  "Acantilados",
];

module.exports = names;
