const colors = [
  "Reds",
  "Blues",
  "Greens",
  "Yellows",
  "Purples",
  "Pinks",
  "Browns",
  "Oranges",
  "Grays",
  "Blacks",
  "Whites",
  "Golds",
  "Silvers",
  "Teals",
  "Indigos",
  "Magentas",
  "Violets",
  "Aeros",
  "Maroons",
  "Ambers",
  "Azures",
  "Aureolins",
  "Siennas",
  "Carmines",
  "Celestes",
  "Cerises",
  "Ceruleans",
  "Fuchsias",
  "Flaxes",
  "Flames",
  "Finns",
  "Fawns",
  "Erins",
  "Emeralds",
  "Eggshells",
  "Creams",
];

module.exports = colors;
