const names = [
  "Titani",
  "Tempesta",
  "Guerrieri",
  "Tuono",
  "Draghi",
  "Razzi",
  "Fenice",
  "Onde",
  "Valanga",
  "Squali martello",
  "Uragani",
  "Mavericks",
  "Martello",
  "Scudo",
  "Frecce",
  "Sciabole",
  "Tridente",
  "Guanto",
  "Torcia",
  "Armatura",
  "Ali",
  "Corona",
  "Ancore",
  "Lama",
  "Scettro",
  "Elmo",
  "Pugnale",
  "Sfera",
  "Orb",
  "Artiglio",
  "Cresta",
  "Raptor",
  "Blazers",
  "Uccelli di fuoco",
  "Guardiani",
  "Cicloni",
  "Comete",
  "Fuorilegge",
  "Fantasmi",
  "Tempestosi",
  "Caricabatterie",
  "Stormhawks",
  "Predoni",
  "Vichinghi",
  "Inferno",
  "Predatori",
  "Fulmini",
  "Grifoni",
  "Ribelli",
  "Battitori",
  "Troiani",
  "Re",
  "Cobalto",
  "Ombre",
  "Bucanieri",
  "Jet",
  "Maghi",
  "Grifoni",
  "Reali",
  "Leggende",
  "Furia",
  "Arcieri",
  "Razze di razza",
  "Galassia",
  "Scogliere",
];

module.exports = names;
