const occupations = [
  "Boulangers",
  "Couturiers",
  "Agriculteurs",
  "Peintres",
  "Marins",
  "Ingénieurs",
  "Écrivains",
  "Athlètes",
  "Potiers",
  "Tisserands",
  "Maçons",
  "Menuisiers",
  "Forgerons",
  "Pilotes",
  "Officiers",
  "Jardiniers",
  "Mécaniciens",
  "Cordonniers",
  "Chasseurs",
  "Plombiers",
  "Constructeurs",
  "Tonneliers",
  "Meuniers",
  "Batteurs de laine",
  "Palefreniers",
  "Vignerons",
  "Cordiers",
  "Laboureurs",
  "Aubergistes",
  "Merciers",
  "Luthiers",
  "Enlumineurs",
  "Marchands",
  "Aubergistes",
  "Chandlers",
  "Fabricants de fuseaux",
  "Fabricants de tamis",
  "Scribes",
  "Laboureurs",
  "Archers",
  "Quincailliers",
  "Gantiers",
  "Maréchaux-ferrants",
  "Fabricants d'arcs",
  "Fabricants de cloches",
  "Vanniers",
  "Fabricants de fourreaux",
  "Palefreniers",
  "Herseurs",
  "Doreurs",
  "Fléchiers",
  "Barde",
  "Aubergistes",
  "Charrons",
  "Vendeurs d'indulgences",
  "Baillis",
  "Colporteurs",
  "Tisserands",
  "Vendeurs d'indulgences",
  "Menuisiers",
  "Saints",
  "Gladiateurs",
  "Artilleurs",
];

module.exports = occupations;
