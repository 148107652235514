const names = [
  "Titans",
  "Tempête",
  "Guerriers",
  "Tonnerre",
  "Dragons",
  "Rockets",
  "Phoenix",
  "Vagues",
  "Avalanche",
  "Marteaux de tête",
  "Ouragans",
  "Mavericks",
  "Marteau",
  "Bouclier",
  "Flèches",
  "Sabres",
  "Trident",
  "Gantelet",
  "Torche",
  "Armure",
  "Ailes",
  "Couronne",
  "Ancres",
  "Lame",
  "Sceptre",
  "Casque",
  "Dague",
  "Sphère",
  "Orbe",
  "Serre",
  "Crête",
  "Raptors",
  "Blazers",
  "Oiseaux de feu",
  "Gardiens",
  "Cyclones",
  "Comètes",
  "Hors-la-loi",
  "Fantômes",
  "Stormers",
  "Chargeurs",
  "Stormhawks",
  "Raiders",
  "Vikings",
  "Inferno",
  "Rôdeurs",
  "Éclairs",
  "Griffons",
  "Rebelles",
  "Frappeurs",
  "Troyens",
  "Rois",
  "Cobalts",
  "Ombres",
  "Corsaires",
  "Jets",
  "Sorciers",
  "Griffons",
  "Royals",
  "Légendes",
  "Fureur",
  "Archers",
  "Raies piquantes",
  "Galaxie",
  "Falaises",
];

module.exports = names;
