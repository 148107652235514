const animals = [
  "Leoni",
  "Tigri",
  "Orsi",
  "Lupi",
  "Orsi",
  "Falchi",
  "Falchi",
  "Pantere",
  "Giaguari",
  "Ghepardi",
  "Leopardi",
  "Cobre",
  "Vipere",
  "Squali",
  "Delfini",
  "Balene",
  "Orche",
  "Coccodrilli",
  "Alligatori",
  "Bovini",
  "Broncos",
  "Mustang",
  "Puledri",
  "Arieti",
  "Felini selvatici",
  "Linci",
  "Linci",
  "Puma",
  "Corvi",
  "Gufo",
  "Zebre",
  "Giraffe",
  "Gazelle",
  "Rinoceronti",
  "Elefanti",
  "Cani",
  "Capre",
  "Volpi",
  "Cervi",
  "Panda",
  "Coyote",
  "Bisoni",
  "Serpenti",
  "Cavalli",
  "Akitas",
  "Pipistrelli",
  "Cammelli",
  "Coralli",
  "Aquila",
  "Cervi",
  "Gorilla",
  "Pappagalli",
  "Conigli",
  "Capesante",
  "Calmari",
  "Cigni",
  "Avvoltoio",
  "Yak",
  "Scorpioni",
];

module.exports = animals;
