const colors = [
  "Rojo",
  "Azul",
  "Verde",
  "Amarillo",
  "Morado",
  "Rosa",
  "Marrón",
  "Naranja",
  "Gris",
  "Negro",
  "Blanco",
  "Oro",
  "Plata",
  "Turquesa",
  "Índigo",
  "Magenta",
  "Violeta",
  "Aero",
  "Granate",
  "Ámbar",
  "Azul celeste",
  "Aureolina",
  "Siena",
  "Carmín",
  "Celeste",
  "Cereza",
  "Azul cerúleo",
  "Fucsia",
  "Lino",
  "Llama",
  "Finés",
  "Gamuza",
  "Esmeralda",
  "Cáscara de huevo",
  "Crema",
];

module.exports = colors;
