const animals = [
  "Lions",
  "Tigres",
  "Ours",
  "Loups",
  "Ours",
  "Faucons",
  "Éperviers",
  "Panthères",
  "Jaguars",
  "Guépards",
  "Léopards",
  "Cobras",
  "Vipères",
  "Requins",
  "Dauphins",
  "Baleines",
  "Orques",
  "Crocodiles",
  "Alligators",
  "Taureaux",
  "Broncos",
  "Mustangs",
  "Étalons",
  "Béliers",
  "Lynx",
  "Lynx",
  "Lynx",
  "Pumas",
  "Corbeaux",
  "Chouettes",
  "Zèbres",
  "Girafes",
  "Gazelles",
  "Rhinocéros",
  "Éléphants",
  "Chiens",
  "Chèvres",
  "Renards",
  "Cerfs",
  "Pandas",
  "Coyotes",
  "Bisons",
  "Serpents",
  "Chevaux",
  "Akitas",
  "Chauves-souris",
  "Chameaux",
  "Coraux",
  "Aigles",
  "Élans",
  "Gorilles",
  "Perroquets",
  "Lapins",
  "Coquilles Saint-Jacques",
  "Calmars",
  "Cygnes",
  "Vautour",
  "Yaks",
  "Scorpions",
];

module.exports = animals;
