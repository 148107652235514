import logo from "./logo.svg";
import "./App.css";
import { Button, Checkbox, Form, Input, Select } from "antd";
// ENG
import adjectives_en from "./constants/eng/adj";
import animals_en from "./constants/eng/animals";
import colors_en from "./constants/eng/color";
import names_en from "./constants/eng/names";
import occupations_en from "./constants/eng/occ";
// ESP
import adjectives_es from "./constants/esp/adj_ESP";
import animals_es from "./constants/esp/animals_ESP";
import colors_es from "./constants/esp/color_ESP";
import names_es from "./constants/esp/names_ESP";
import occupations_es from "./constants/esp/occ_ESP";
// FRE
import adjectives_fr from "./constants/fre/adj_FRA";
import animals_fr from "./constants/fre/animals_FRA";
import colors_fr from "./constants/fre/color_FRA";
import names_fr from "./constants/fre/names_FRA";
import occupations_fr from "./constants/fre/occ_FRA";
// ITA
import adjectives_it from "./constants/ita/adj_ITA";
import animals_it from "./constants/ita/animals_ITA";
import colors_it from "./constants/ita/color_ITA";
import names_it from "./constants/ita/names_ITA";
import occupations_it from "./constants/ita/occ_ITA";
// GER
import adjectives_gr from "./constants/ger/adj_GER";
import animals_gr from "./constants/ger/animals_GER";
import colors_gr from "./constants/ger/color_GER";
import names_gr from "./constants/ger/names_GER";
import occupations_gr from "./constants/ger/occ_GER";
// VAT
import adjectives_lt from "./constants/lat/adj_VAT";
import animals_lt from "./constants/lat/animals_VAT";
import colors_lt from "./constants/lat/color_VAT";
import names_lt from "./constants/lat/names_VAT";
import occupations_lt from "./constants/lat/occ_VAT";

import descriptors from "./constants/desc";
import historic_references from "./constants/historic_references";
import legends from "./constants/legends";
import organization from "./constants/organization";
import { useEffect, useState } from "react";

function App() {
  // Function to pick a random item from an array

  const [myClubName, setMyClubName] = useState("");
  const [city, setCity] = useState("");
  const [checkBox, setCheckbox] = useState(true);
  const [language, setLanguage] = useState("ENG"); // eng, italian, german, latin, french, spanish

  const [adjectives, setAdjectives] = useState(adjectives_en);
  const [animals, setAnimals] = useState(animals_en);
  const [colors, setColors] = useState(colors_en);
  const [names, setNames] = useState(names_en);
  const [occupations, setOoccupations] = useState(occupations_en);

  function getRandomItem(array) {
    const index = Math.floor(Math.random() * array.length);
    return array[index];
  }

  const decideWhatLang = () => {
    // animals
    // names
    // adj
    // occ
    // colors
    console.log("language:", language);
    if (language) {
      if (language === "ENG") {
        setAnimals(animals_en);
        setNames(names_en);
        setAdjectives(adjectives_en);
        setOoccupations(occupations_en);
        setColors(colors_en);
      }
      if (language === "ESP") {
        setAnimals(animals_es);
        setNames(names_es);
        setAdjectives(adjectives_es);
        setOoccupations(occupations_es);
        setColors(colors_es);
      }
      if (language === "LAT") {
        setAnimals(animals_lt);
        setNames(names_lt);
        setAdjectives(adjectives_lt);
        setOoccupations(occupations_lt);
        setColors(colors_lt);
      }
      if (language === "ITA") {
        setAnimals(animals_it);
        setNames(names_it);
        setAdjectives(adjectives_it);
        setOoccupations(occupations_it);
        setColors(colors_it);
      }
      if (language === "GER") {
        setAnimals(animals_gr);
        setNames(names_gr);
        setAdjectives(adjectives_gr);
        setOoccupations(occupations_gr);
        setColors(colors_gr);
      }
      if (language === "FRE") {
        setAnimals(animals_fr);
        setNames(names_fr);
        setAdjectives(adjectives_fr);
        setOoccupations(occupations_fr);
        setColors(colors_fr);
      }
    } else {
      setAnimals(animals_en);
      setNames(names_en);
      setAdjectives(adjectives_en);
      setOoccupations(occupations_en);
      setColors(colors_en);
    }
  };

  const generateClubName = (e) => {
    let location = e ? e?.target?.value : city;

    if (e && checkBox) {
      location = city ? city : "";
    }

    let prefix = "";
    let middle = location || "";
    let suffix = "";

    let isOrganizationChosen = false;

    // Choose prefix
    if (Math.random() < 0.5) {
      let prefixCategory;
      if (!isOrganizationChosen && Math.random() < 0.5) {
        prefixCategory = organization;
        isOrganizationChosen = true;
      } else if (Math.random() < 0.5) {
        prefixCategory = historic_references;
      } else {
        prefixCategory = [
          Math.floor(Math.random() * (1940 - 1901) + 1901).toString(),
        ];
      }
      prefix = getRandomItem(prefixCategory);
    }

    // Choose middle
    if (Math.random() < 0.3 || !middle) {
      let middleCategory;
      if (Math.random() < 0.25) {
        middleCategory = animals;
        console.log("my animals::", animals);
      } else if (Math.random() < 0.33) {
        middleCategory = legends;
      } else if (Math.random() < 0.5) {
        middleCategory = names;
      } else {
        middleCategory = occupations;
      }
      middle += " " + getRandomItem(middleCategory);
    }

    // Add an adjective to the middle 30% of the time if location is not provided
    if (!location && Math.random() < 0.3) {
      middle = getRandomItem(adjectives) + " " + middle;
    }

    // Choose suffix
    if (Math.random() < 0.5) {
      let suffixCategory;
      if (!isOrganizationChosen && Math.random() < 0.5) {
        suffixCategory = organization;
        isOrganizationChosen = true;
      } else if (Math.random() < 0.5) {
        suffixCategory = descriptors;
      } else {
        let suffixSubCategory;
        if (Math.random() < 0.5) {
          suffixSubCategory = animals;
        } else {
          suffixSubCategory = colors;
        }
        suffixCategory = suffixSubCategory;
      }
      suffix = getRandomItem(suffixCategory);
    }

    // Return full club name
    setMyClubName((prefix + " " + middle + " " + suffix).trim());
    // return (prefix + " " + middle + " " + suffix).trim();
  };

  const onCity = (e) => {
    setCity(e.target.value);
    generateClubName(e.target.value);
  };

  const onCheckBox = (e) => {
    console.log("eeee??", e.target.checked);
    setCheckbox(e.target.checked);
  };
  // console.log(generateClubName("Manchester"));

  const onLanguage = (value) => {
    setLanguage(value);
    decideWhatLang();
  };

  useEffect(() => {
    generateClubName("");
    decideWhatLang();
  }, [city, language, animals, occupations, names, adjectives, colors]);
  // animals
  // names
  // adj
  // occ
  // colors
  return (
    <>
      <div className="container">
        <h1 style={{ fontSize: "25px", marginBottom: "50px" }}>
          Football Club Name Generator
        </h1>
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Checkbox onChange={onCheckBox}>Use club's location</Checkbox>
          <Select
            defaultValue="ENG"
            style={{ width: 120 }}
            onChange={onLanguage}
          >
            <Select.Option value="ENG">
              <img
                width={20}
                src={
                  "http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg"
                }
                alt=""
              />{" "}
              English
            </Select.Option>
            <Select.Option value="GER">
              <img
                width={20}
                src={
                  "http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg"
                }
                alt=""
              />{" "}
              German
            </Select.Option>
            <Select.Option value="ESP">
              <img
                width={20}
                src={
                  "http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg"
                }
                alt=""
              />{" "}
              Spanish
            </Select.Option>
            <Select.Option value="ITA">
              <img
                width={20}
                src={
                  "http://purecatamphetamine.github.io/country-flag-icons/3x2/IT.svg"
                }
                alt=""
              />{" "}
              Italian
            </Select.Option>
            <Select.Option value="FRE">
              <img
                width={20}
                src={
                  "http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg"
                }
                alt=""
              />{" "}
              French
            </Select.Option>
            <Select.Option value="LAT">
              <img
                width={20}
                src={
                  "http://purecatamphetamine.github.io/country-flag-icons/3x2/VA.svg"
                }
                alt=""
              />{" "}
              Latin
            </Select.Option>
          </Select>
        </div>
        <div className="body">
          <Input
            disabled={!checkBox}
            placeholder="Type your club's city, region or neighborhood"
            onKeyUp={onCity}
          />
        </div>
        <div
          className="footer"
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button type="primary" onClick={generateClubName}>
            Generate
          </Button>
        </div>

        <div
          className="result"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          <p>Your Club Name:</p>
          <h1>{myClubName}</h1>
        </div>
      </div>
    </>
  );
}

export default App;
