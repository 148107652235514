const colors = [
  "Rubei",
  "Caerulei",
  "Virides",
  "Flavi",
  "Purpurae",
  "Rosae",
  "Brunei",
  "Aurantiaci",
  "Grisei",
  "Nigri",
  "Albi",
  "Aurei",
  "Argentei",
  "Thalassini",
  "Indigones",
  "Magentae",
  "Violacei",
  "Candidi",
  "Cuprei",
  "Ambrae",
  "Azuri",
  "Aureolani",
  "Siennae",
  "Carminis",
  "Celestes",
  "Cerisei",
  "Cerulei",
  "Fuchsii",
  "Flavi",
  "Flammei",
  "Finns",
  "Fawns",
  "Erins",
  "Smaragdi",
  "Testudinei",
  "Cremorum",
];

module.exports = colors;
