const occupations = [
  "Pistores",
  "Sartores",
  "Agricolae",
  "Pictores",
  "Nautae",
  "Ingeniarii",
  "Scriptores",
  "Athletae",
  "Figulini",
  "Textores",
  "Masones",
  "Carpentarii",
  "Ferrarii",
  "Aviatores",
  "Duces",
  "Horticultores",
  "Machinarii",
  "Calceolarii",
  "Venatores",
  "Tubistae",
  "Aedificatores",
  "Doliarii",
  "Molendinarii",
  "Fullones",
  "Equitatores",
  "Coloni",
  "Vitellarii",
  "Roparii",
  "Aratrii",
  "Caupo",
  "Revestiarii",
  "Illuminatores",
  "Mercatores",
  "Cervisariae",
  "Saponarii",
  "Fusores",
  "Cribriarii",
  "Scribae",
  "Aratrii",
  "Arcubalistae",
  "Ferrarii",
  "Venefici",
  "Ferramentarii",
  "Arcuarii",
  "Campanarii",
  "Viminae",
  "Fabricatores",
  "Stallarii",
  "Aratori",
  "Aurifices",
  "Sagittarii",
  "Bardi",
  "Cervisariae",
  "Curatores Rotarum",
  "Venatores",
  "Tornatores",
  "Telarii",
  "Curatores Rotarum",
  "Carpentarios",
  "Sancti",
  "Gladiatores",
  "Fulminatores",
];

module.exports = occupations;
