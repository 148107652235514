const animals = [
  "Lions",
  "Tigers",
  "Bears",
  "Wolves",
  "Bears",
  "Falcons",
  "Hawks",
  "Panthers",
  "Jaguars",
  "Cheetahs",
  "Leopards",
  "Cobras",
  "Vipers",
  "Sharks",
  "Dolphins",
  "Whales",
  "Orcas",
  "Crocodiles",
  "Alligators",
  "Bulls",
  "Broncos",
  "Mustangs",
  "Stallions",
  "Rams",
  "Wildcats",
  "Bobcats",
  "Lynxes",
  "Pumas",
  "Ravens",
  "Owls",
  "Zebras",
  "Giraffes",
  "Gazelles",
  "Rhinos",
  "Elephants",
  "Dogs",
  "Goats",
  "Foxes",
  "Deers",
  "Pandas",
  "Coyotes",
  "Buffalos",
  "Snakes",
  "Horses",
  "Akitas",
  "Bats",
  "Camels",
  "Corals",
  "Eagles",
  "Elks",
  "Gorillas",
  "Parrots",
  "Rabbits",
  "Scallops",
  "Squids",
  "Swans",
  "Vulture",
  "Yaks",
  "Scorpions",
];

module.exports = animals;
