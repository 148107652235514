const names = [
  "Titanen",
  "Sturm",
  "Krieger",
  "Donner",
  "Drachen",
  "Raketen",
  "Phoenix",
  "Wellen",
  "Lawine",
  "Hammerhaie",
  "Hurrikane",
  "Mavericks",
  "Hammer",
  "Schild",
  "Pfeile",
  "Säbel",
  "Dreizack",
  "Panzerhandschuh",
  "Fackel",
  "Rüstung",
  "Flügel",
  "Krone",
  "Anker",
  "Klinge",
  "Zepter",
  "Helm",
  "Dolch",
  "Kugel",
  "Orb",
  "Kralle",
  "Wappen",
  "Raptoren",
  "Blazers",
  "Firebirds",
  "Wächter",
  "Wirbelstürme",
  "Kometen",
  "Outlaws",
  "Phantome",
  "Sturmtruppler",
  "Ladegeräte",
  "Stormhawks",
  "Räuber",
  "Wikinger",
  "Inferno",
  "Einbrecher",
  "Donnerbolzen",
  "Greifen",
  "Rebellen",
  "Strikers",
  "Trojaner",
  "Könige",
  "Kobalts",
  "Schatten",
  "Seeräuber",
  "Jets",
  "Hexenmeister",
  "Greifen",
  "Königliche",
  "Legenden",
  "Fury",
  "Bogenschützen",
  "Stachelrochen",
  "Galaxie",
  "Klippen",
];

module.exports = names;
